




















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class About extends Vue {
  //ダイアログ用フラグ
  isExDialog: Boolean[] = [false, false, false];

  //アンカー スムーズスクロール
  toAnchor(e: Event) {
    let target = e.target as HTMLElement;
    let id =
      target instanceof HTMLElement === true ? target.getAttribute('href') : '';
    if (id !== null) {
      id = id.replace('#', '');
    }
    let elem =
      id !== null
        ? (document.getElementById(id) as HTMLElement)
        : new HTMLElement();
    let top =
      elem instanceof HTMLElement === true
        ? window.pageYOffset + elem.getBoundingClientRect().top
        : 0;

    window.scrollTo({
      top: top,
      behavior: 'smooth'
    });
    e.preventDefault();
  }

  //QA表示・非表示トグル動作（DOMを直接操作している）
  toggleAnswer(e: Event) {
    let target = e.target as HTMLElement;
    let targetParent = target.parentNode as HTMLElement;
    let targetBox = targetParent.nextSibling as HTMLElement;
    if (target.getAttribute('aria-expanded') === 'true') {
      target.setAttribute('aria-expanded', 'false');
      targetBox.setAttribute('aria-hidden', 'true');
    } else {
      target.setAttribute('aria-expanded', 'true');
      targetBox.setAttribute('aria-hidden', 'false');
    }
  }

  get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  clickLogin() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: '/odekake-point' } });
  }
  private signup() {
    this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }
}
