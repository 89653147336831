import {
  ResAdultIdi,
  ResAdultIdiLinkHistories,
  ResChildPointIdi
} from '@/gen/shiva';

// AdultPasmoIdi 大人用IDiクラス
// APIから返された大人用IDiを画面に表示する目的で利用する。
// APIから返された大人用IDiを格納し、自身のインスタンスを返す。
export class AdultPasmoIdi {
  private constructor(readonly adultIdi: string) {}

  static valueOf(data: ResAdultIdi) {
    return new AdultPasmoIdi(data.adult_idi);
  }
}

// ChildPasmoIdis 小児用IDiクラス
// APIから返された小児用IDi（最大3枚）を画面に表示する目的で利用する。
// APIから返された小児用IDiの配列を格納し、自身のインスタンスを返す。
export class ChildPasmoIdis {
  private constructor(readonly childIdis: string[]) {}

  static valueOf(data: string[]) {
    return new ChildPasmoIdis(data);
  }
}

// AdultIdiLinkHistories 大人用IDi紐付け履歴データクラス
// AdultIdiLinkHistoriesListクラスから返される配列の一つの要素として利用する。
// 大人用IDi紐付け履歴を1件格納する。
export class AdultIdiLinkHistories {
  private constructor(
    readonly adultIdi: string,
    readonly startDatetime: string,
    readonly endDatetime: string
  ) {}

  static valueOf(data: ResAdultIdiLinkHistories) {
    return new AdultIdiLinkHistories(
      data.adult_idi,
      data.start_datetime,
      data.end_datetime
    );
  }
}

// AdultIdiLinkHistoriesList AdultIdiLinkHistoriesクラスの配列を返すクラス
// APIから返された大人用IDi紐付け履歴データを独自定義したクラスに移し替え、画面に表示する目的で利用する。
// APIから返された大人用IDi紐付け履歴の配列をAdultIdiLinkHistoriesクラスに移し替え、自身のインスタンスを返す。
export class AdultIdiLinkHistoriesList {
  private constructor() {}

  static valueOf(data: ResAdultIdiLinkHistories[]) {
    return data.map(e => AdultIdiLinkHistories.valueOf(e));
  }
}

// ChildPointIdi ポイント付与対象小児用IDiデータクラス
// ChildPointIdiListクラスから返される配列の一つの要素として利用する。
// ポイント付与対象小児用IDiを1件格納する。
export class ChildPointIdi {
  private constructor(readonly period: string, readonly childIdis: string[]) {}

  static valueOf(data: ResChildPointIdi) {
    return new ChildPointIdi(data.period, data.child_idis);
  }
}

// ChildPointIDiList ChildPointIdiクラスの配列を返すクラス
// APIから返されたポイント付与対象小児用IDiデータを独自定義したクラスに移し替え、画面に表示する目的で利用する。
// APIから返されたポイント付与対象小児用IDiの配列をChildPointIdiクラスに移し替え、自身のインスタンスを返す。
export class ChildPointIdiList {
  private constructor() {}

  static valueOf(data: ResChildPointIdi[]) {
    return data.map(e => ChildPointIdi.valueOf(e));
  }
}
