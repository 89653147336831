import Vue from 'vue';
import * as shivaApi from '@/gen/shiva/api';
import {
  AdultPasmoIdi,
  ChildPasmoIdis,
  AdultIdiLinkHistoriesList,
  ChildPointIdiList
} from '@/models/PasmoIdi';

export class PasmoIdiRepository {
  private async idiApiClient() {
    const config = await Vue.prototype.$getShivaConfigWithToken(
      Vue.prototype.$auth
    );
    return new shivaApi.IdiApi(config);
  }

  async registerAdultPasmoIdi(adultPasmoIdi: string) {
    const client = await this.idiApiClient();
    try {
      const res = await client.createAdultIdi({
        idi: adultPasmoIdi
      });
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async removeAdultPasmoIdi(adultPasmoIdi: string) {
    const client = await this.idiApiClient();
    try {
      const res = await client.deleteAdultIdi({
        idi: adultPasmoIdi
      });
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getAdultPasmoIdi() {
    const client = await this.idiApiClient();
    try {
      const res = await client.getAdultIdi();
      const { data } = res;
      return AdultPasmoIdi.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async removeChildPasmoIdi(childPasmoIdi: string) {
    const client = await this.idiApiClient();
    try {
      const res = await client.deleteChildIdi({
        child_idi: childPasmoIdi
      });
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getChildPasmoIdis() {
    const client = await this.idiApiClient();
    try {
      const res = await client.getChildIdi();
      const { data } = res;
      return ChildPasmoIdis.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async registerChildPasmoIdi(childPasmoIdi: string) {
    const client = await this.idiApiClient();
    try {
      const res = await client.createChildIdi({
        child_idi: childPasmoIdi
      });
      return Promise.resolve(res);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getAdultIdiLinkHistories() {
    const client = await this.idiApiClient();
    try {
      const res = await client.getAdultIdiLinkHistories();
      const { data } = res;
      return AdultIdiLinkHistoriesList.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }

  async getChildIdiLinkHistories() {
    const client = await this.idiApiClient();
    try {
      const res = await client.getChildPointIdi();
      const { data } = res;
      return ChildPointIdiList.valueOf(data);
    } catch (err) {
      const res = (err as any).response;
      return Promise.reject(res.data.code ? res.data.code : res.status);
    }
  }
}
