var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"gBody"},[_c('div',{staticClass:"gBody_contents"},[_c('div',{class:_vm.$auth.isAuthenticated ? 'mv' : 'mv mv-mb'},[_vm._m(0),_c('svg',{class:_vm.$auth.isAuthenticated ? 'mv_mask mv_mask-blue' : 'mv_mask'},[_c('use',{attrs:{"xlink:href":"#SvgVisMask"}})])]),_c('div',{staticClass:"gBody_inner u-inner"},[(!_vm.$auth.isAuthenticated)?[_c('nav',{staticClass:"loginNav"},[_c('ul',{staticClass:"loginNav_list"},[_c('li',[_c('p',{staticClass:"loginNav_list_text"},[_vm._v(" おでかけポイントの履歴確認やPASMO登録 ")]),_c('p',{staticClass:"loginNav_list_link"},[_c('button',{staticClass:"loginNav_list_link_item u-btn u-btn-grad",attrs:{"type":"button"},on:{"click":_vm.clickLogin}},[_vm._v(" ログイン"),_c('i',{staticClass:"icon icon-linkRight"})])])]),_c('li',[_vm._m(1),_c('p',{staticClass:"loginNav_list_link"},[_c('button',{staticClass:"loginNav_list_link_item u-btn",attrs:{"type":"button"},on:{"click":_vm.clickSignup}},[_vm._v(" 小田急ONE 新規会員登録"),_c('i',{staticClass:"icon icon-linkRight"})])])])])]),_c('About')]:[_c('nav',{staticClass:"tabNav u-fit"},[_c('ul',{staticClass:"tabNav_list tabNav_list-3c"},[_c('li',[_c('router-link',{attrs:{"to":"/odekake-point?tab=info","aria-current":_vm.LIST_TAB_ORDER[_vm.active_tab] ===
                  _vm.TAB_USAGE_HISTORY_AND_POINT_DETAIL
                    ? 'page'
                    : 'false'}},[_vm._v("乗車履歴・"),_c('br',{staticClass:"u-spInline"}),_vm._v("ポイント詳細")])],1),_c('li',[_c('router-link',{attrs:{"to":"/odekake-point?tab=manage","aria-current":_vm.LIST_TAB_ORDER[_vm.active_tab] === _vm.TAB_PASMO_MANAGE
                    ? 'page'
                    : 'false'}},[_vm._v("PASMO ID番号"),_c('br',{staticClass:"u-spInline"}),_vm._v("登録・管理")])],1),_c('li',[_c('router-link',{attrs:{"to":"/odekake-point?tab=about","aria-current":_vm.LIST_TAB_ORDER[_vm.active_tab] === _vm.TAB_ABOUT ? 'page' : 'false'}},[_vm._v("小田急"),_c('br'),_vm._v(" おでかけ"),_c('br',{staticClass:"u-spsInline"}),_vm._v("ポイント"),_c('br',{staticClass:"u-spInline"}),_vm._v(" とは")])],1)])]),(
            _vm.LIST_TAB_ORDER[_vm.active_tab] === _vm.TAB_USAGE_HISTORY_AND_POINT_DETAIL
          )?_c('UsageHistoryAndPointDetail'):(_vm.LIST_TAB_ORDER[_vm.active_tab] === _vm.TAB_PASMO_MANAGE)?_c('PasmoManage'):(_vm.LIST_TAB_ORDER[_vm.active_tab] === _vm.TAB_ABOUT)?_c('About'):_vm._e()]],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mv_inner u-inner"},[_c('div',{staticClass:"mv_contents"},[_c('div',{staticClass:"odepMv u-marugo"},[_c('div',{staticClass:"odepMv_bg odepMv_bg-left u-spSwitchImg"},[_c('img',{attrs:{"src":"/static/assets/img/odekake-point/bg-main-left@2x.png","width":"385","height":"170","alt":""}}),_c('img',{attrs:{"src":"/static/assets/img/odekake-point/bg-main-left_sp@2x.png.png","width":"68","height":"146","alt":""}})]),_c('div',{staticClass:"odepMv_bg odepMv_bg-right u-spSwitchImg"},[_c('img',{attrs:{"src":"/static/assets/img/odekake-point/bg-main-right@2x.png","width":"385","height":"170","alt":""}}),_c('img',{attrs:{"src":"/static/assets/img/odekake-point/bg-main-right_sp@2x.png","width":"68","height":"146","alt":""}})]),_c('div',{staticClass:"odepMv_areaText"},[_c('p',{staticClass:"odepMv_catch"},[_vm._v(" PASMOで小田急線に乗ると"),_c('br'),_vm._v("小田急ポイントが貯まるおトクなサービス ")]),_c('h1',{staticClass:"odepMv_title"},[_vm._v("「小田急おでかけポイント」")])]),_c('div',{staticClass:"odepMv_image"},[_c('img',{attrs:{"src":"/static/assets/img/odekake-point/img-main@2x.png","width":"400","height":"96","alt":""}})])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"loginNav_list_text"},[_vm._v(" 小田急ONEに登録して"),_c('br',{staticClass:"u-spInline"}),_vm._v("おでかけポイントをはじめよう ")])}]

export { render, staticRenderFns }