// tslint:disable
/**
 * shiva
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 時刻の操作 - `fix`     時刻を固定する操作（時刻が止まる） - `set`     時刻を指定する操作（時刻が進む） - `add`     設定されている時刻に時間を足す操作 - `restore` 時刻を現在時刻にする操作
     * @type {string}
     * @memberof InlineObject
     */
    operation: InlineObjectOperationEnum;
    /**
     * 時刻の設定値（タイムゾーンも指定すること） - `2022-02-09T08:00:00` operation が fix, set の場合はこの形式 - `2h`                  operation が add の場合は golang の duration にパースできる形式 - operation が restore の場合は入力不要（入力してもパラメタが使われない）
     * @type {string}
     * @memberof InlineObject
     */
    time?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineObjectOperationEnum {
    Fix = 'fix',
    Restore = 'restore',
    Set = 'set',
    Add = 'add'
}

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200
     */
    message?: InlineResponse200MessageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InlineResponse200MessageEnum {
    OK = 'OK'
}

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2001
     */
    time?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2002
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse500
 */
export interface InlineResponse500 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse500
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * Error Code
     * @type {number}
     * @memberof ModelError
     */
    code: number;
    /**
     * Error Message
     * @type {string}
     * @memberof ModelError
     */
    message: string;
}
/**
 * APIを有効にしなかった場合
 * @export
 * @interface NotFound404
 */
export interface NotFound404 {
    /**
     * 
     * @type {number}
     * @memberof NotFound404
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof NotFound404
     */
    message: string;
}
/**
 * 大人用IDi登録・登録解除リクエスト
 * @export
 * @interface ReqAdultIdiPost
 */
export interface ReqAdultIdiPost {
    /**
     * ユーザーの大人用PASMO IDi
     * @type {string}
     * @memberof ReqAdultIdiPost
     */
    idi: string;
}
/**
 * 小児用IDi登録・登録解除リクエスト
 * @export
 * @interface ReqChildIdiPost
 */
export interface ReqChildIdiPost {
    /**
     * ユーザーの小児用PASMO IDi
     * @type {string}
     * @memberof ReqChildIdiPost
     */
    child_idi: string;
}
/**
 * 大人用IDi取得結果
 * @export
 * @interface ResAdultIdi
 */
export interface ResAdultIdi {
    /**
     * 大人用IDi データ無しの場合空文字を返却する。
     * @type {string}
     * @memberof ResAdultIdi
     */
    adult_idi: string;
}
/**
 * 大人用IDi登録・解除履歴取得結果 データ無しの場合空の配列を返却する。
 * @export
 * @interface ResAdultIdiLinkHistories
 */
export interface ResAdultIdiLinkHistories {
    /**
     * 大人用IDi
     * @type {string}
     * @memberof ResAdultIdiLinkHistories
     */
    adult_idi: string;
    /**
     * IDi登録日時
     * @type {string}
     * @memberof ResAdultIdiLinkHistories
     */
    start_datetime: string;
    /**
     * IDi登録解除日時
     * @type {string}
     * @memberof ResAdultIdiLinkHistories
     */
    end_datetime: string;
}
/**
 * 小児用ポイント付与対象IDi取得結果 データ無しの場合も過去3ヶ月分返却する。その場合各対象年月ごとのchild_idisが空の配列で返却される。
 * @export
 * @interface ResChildPointIdi
 */
export interface ResChildPointIdi {
    /**
     * 対象年月
     * @type {string}
     * @memberof ResChildPointIdi
     */
    period: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResChildPointIdi
     */
    child_idis: Array<string>;
}
/**
 * Debug Info response
 * @export
 * @interface ResDebugInfo
 */
export interface ResDebugInfo {
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    system_time?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    uid?: string;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    commit_rev?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResDebugInfo
     */
    env?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResDebugInfo
     */
    mgr_token?: string;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    pubkey_cache?: object;
    /**
     * 
     * @type {object}
     * @memberof ResDebugInfo
     */
    mgr_token_cache?: object;
}
/**
 * 大人用乗降車ポイント付与数取得結果
 * @export
 * @interface ResEntrainAdultPoints
 */
export interface ResEntrainAdultPoints {
    /**
     * 大人用乗降車ポイント付与数取得結果 各区間で1回目の乗車データ（付与率、ポイント数が0のデータ）はレスポンスに含めない データ無しの場合空の配列を返却する。
     * @type {Array<ResEntrainAdultPointsAdultPoints>}
     * @memberof ResEntrainAdultPoints
     */
    adult_points: Array<ResEntrainAdultPointsAdultPoints>;
    /**
     * データ処理日付 yyyy-MM-dd形式 データ無しの場合空文字を返却する。
     * @type {string}
     * @memberof ResEntrainAdultPoints
     */
    latest_operated_date: string;
}
/**
 * 
 * @export
 * @interface ResEntrainAdultPointsAdultPoints
 */
export interface ResEntrainAdultPointsAdultPoints {
    /**
     * 区間運賃
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    fare: number;
    /**
     * 現在の付与率 ％に変換するため（0 <= rate <= 1）
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    rate: number;
    /**
     * 区間付与対象乗車回数
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    count: number;
    /**
     * 区間付与ポイント数
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    point: number;
    /**
     * 次の付与率までの残り回数 最高付与率に到達している場合、0を返す
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    next_rate_count: number;
    /**
     * 次の付与率 ％に変換するため（0 <= rate <= 1） 最高付与率に到達している場合、0を返す
     * @type {number}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    next_rate: number;
    /**
     * 
     * @type {Array<ResEntrainAdultPointsBreakdown>}
     * @memberof ResEntrainAdultPointsAdultPoints
     */
    breakdown: Array<ResEntrainAdultPointsBreakdown>;
}
/**
 * 
 * @export
 * @interface ResEntrainAdultPointsBreakdown
 */
export interface ResEntrainAdultPointsBreakdown {
    /**
     * 付与率 ％に変換するため（0 <= rate <= 1）
     * @type {number}
     * @memberof ResEntrainAdultPointsBreakdown
     */
    rate: number;
    /**
     * 付与率ごとの説明文の1つ目の数値 ex:「2〜3回乗車付与率」の\"2\"
     * @type {string}
     * @memberof ResEntrainAdultPointsBreakdown
     */
    rate_count_min: string;
    /**
     * 付与率ごとの説明文の2つ目の数値 ex:「2〜3回乗車付与率」の\"3\"
     * @type {string}
     * @memberof ResEntrainAdultPointsBreakdown
     */
    rate_count_max: string;
    /**
     * 付与率ごとの付与対象乗車回数
     * @type {number}
     * @memberof ResEntrainAdultPointsBreakdown
     */
    count: number;
}
/**
 * 小児用乗降車ポイント付与数取得結果
 * @export
 * @interface ResEntrainChildPoints
 */
export interface ResEntrainChildPoints {
    /**
     * ポイント データ無しの場合nullを返却する。
     * @type {number}
     * @memberof ResEntrainChildPoints
     */
    point: number | null;
    /**
     * データ処理日付 yyyy-MM-dd形式 データ無しの場合空文字を返却する。
     * @type {string}
     * @memberof ResEntrainChildPoints
     */
    latest_operated_date: string;
}

/**
 * EntrainApi - axios parameter creator
 * @export
 */
export const EntrainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能: [P0174] 大人用乗降車ポイント付与数取得API 過去3ヶ月分の大人用乗降車ポイントの付与数を参照するために 「大人用乗降車ポイント付与数計算」機能にて計算した結果を運賃区間ごとの項目に分けて画面に返却する。 各区間で1回目の乗車データ（付与率、ポイント数が0のデータ）はレスポンスに含めない。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブル、乗降車ポイント付与率テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntrainAdultPoints: async (yearMonth: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            if (yearMonth === null || yearMonth === undefined) {
                throw new RequiredError('yearMonth','Required parameter yearMonth was null or undefined when calling getEntrainAdultPoints.');
            }
            const localVarPath = `/entrain/adult/points`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (yearMonth !== undefined) {
                localVarQueryParameter['year_month'] = yearMonth;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0176] 小児用乗降車ポイント付与数取得API パラメータの対象年月の小児用乗降車ポイント付与数を参照するために 対象年月にユーザーが登録しているIDiのポイントを合算して画面に返却する。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntrainChildPoints: async (yearMonth: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'yearMonth' is not null or undefined
            if (yearMonth === null || yearMonth === undefined) {
                throw new RequiredError('yearMonth','Required parameter yearMonth was null or undefined when calling getEntrainChildPoints.');
            }
            const localVarPath = `/entrain/child/points`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (yearMonth !== undefined) {
                localVarQueryParameter['year_month'] = yearMonth;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntrainApi - functional programming interface
 * @export
 */
export const EntrainApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能: [P0174] 大人用乗降車ポイント付与数取得API 過去3ヶ月分の大人用乗降車ポイントの付与数を参照するために 「大人用乗降車ポイント付与数計算」機能にて計算した結果を運賃区間ごとの項目に分けて画面に返却する。 各区間で1回目の乗車データ（付与率、ポイント数が0のデータ）はレスポンスに含めない。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブル、乗降車ポイント付与率テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntrainAdultPoints(yearMonth: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResEntrainAdultPoints>> {
            const localVarAxiosArgs = await EntrainApiAxiosParamCreator(configuration).getEntrainAdultPoints(yearMonth, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0176] 小児用乗降車ポイント付与数取得API パラメータの対象年月の小児用乗降車ポイント付与数を参照するために 対象年月にユーザーが登録しているIDiのポイントを合算して画面に返却する。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntrainChildPoints(yearMonth: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResEntrainChildPoints>> {
            const localVarAxiosArgs = await EntrainApiAxiosParamCreator(configuration).getEntrainChildPoints(yearMonth, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EntrainApi - factory interface
 * @export
 */
export const EntrainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能: [P0174] 大人用乗降車ポイント付与数取得API 過去3ヶ月分の大人用乗降車ポイントの付与数を参照するために 「大人用乗降車ポイント付与数計算」機能にて計算した結果を運賃区間ごとの項目に分けて画面に返却する。 各区間で1回目の乗車データ（付与率、ポイント数が0のデータ）はレスポンスに含めない。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブル、乗降車ポイント付与率テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntrainAdultPoints(yearMonth: string, options?: any): AxiosPromise<ResEntrainAdultPoints> {
            return EntrainApiFp(configuration).getEntrainAdultPoints(yearMonth, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0176] 小児用乗降車ポイント付与数取得API パラメータの対象年月の小児用乗降車ポイント付与数を参照するために 対象年月にユーザーが登録しているIDiのポイントを合算して画面に返却する。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブルが空の場合、50000エラーにマスキングして返す。
         * @param {string} yearMonth 取得対象年月（yyyyMM形式)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntrainChildPoints(yearMonth: string, options?: any): AxiosPromise<ResEntrainChildPoints> {
            return EntrainApiFp(configuration).getEntrainChildPoints(yearMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntrainApi - object-oriented interface
 * @export
 * @class EntrainApi
 * @extends {BaseAPI}
 */
export class EntrainApi extends BaseAPI {
    /**
     * 機能: [P0174] 大人用乗降車ポイント付与数取得API 過去3ヶ月分の大人用乗降車ポイントの付与数を参照するために 「大人用乗降車ポイント付与数計算」機能にて計算した結果を運賃区間ごとの項目に分けて画面に返却する。 各区間で1回目の乗車データ（付与率、ポイント数が0のデータ）はレスポンスに含めない。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブル、乗降車ポイント付与率テーブルが空の場合、50000エラーにマスキングして返す。
     * @param {string} yearMonth 取得対象年月（yyyyMM形式)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntrainApi
     */
    public getEntrainAdultPoints(yearMonth: string, options?: any) {
        return EntrainApiFp(this.configuration).getEntrainAdultPoints(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0176] 小児用乗降車ポイント付与数取得API パラメータの対象年月の小児用乗降車ポイント付与数を参照するために 対象年月にユーザーが登録しているIDiのポイントを合算して画面に返却する。 乗車履歴、IDi登録・解除履歴が無いユーザーの場合もエラーとせず空のデータを返却する。 一件明細加工バッチ実行履歴テーブルが空の場合、50000エラーにマスキングして返す。
     * @param {string} yearMonth 取得対象年月（yyyyMM形式)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntrainApi
     */
    public getEntrainChildPoints(yearMonth: string, options?: any) {
        return EntrainApiFp(this.configuration).getEntrainChildPoints(yearMonth, options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * IdiApi - axios parameter creator
 * @export
 */
export const IdiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 機能: [P0156] 大人用IDi登録API 画面から入力された大人用IDiをONEアカウントに登録する。 既に他のONEアカウントに登録されているIDiは登録する事ができない。 登録・解除記録をテーブル保持し、同日中に任意のONEアカウントから登録解除されたIDiは再度登録する事はできない。 また、同日中に同ONEアカウントにIDiを2回以上登録することはできない。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdultIdi: async (body?: ReqAdultIdiPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/adult_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0165] 小児用IDi登録API 画面から入力された小児用IDiをONEアカウントに登録する。 他ユーザによって現在登録している小児用IDiは登録する事ができない。 また、一つのONEアカウントへの小児用IDiの登録上限枚数は３枚。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChildIdi: async (body?: ReqChildIdiPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/child_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0157] 大人用IDi登録解除API 現在ONEアカウントに紐付いている大人用IDiの登録を解除する。 確認のために登録解除対象の大人用IDiもパラメータとして受け取る。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdultIdi: async (body?: ReqAdultIdiPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/adult_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0166] 小児用IDi登録解除API 現在ONEアカウントに登録している小児用IDiの登録を解除する。 最大3つ登録している小児用IDiから、解除対象のIDiを特定するため、解除対象の小児用IDiもパラメータとして受け取る。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChildIdi: async (body?: ReqChildIdiPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/child_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0158] 大人用IDi取得API 現在ONEアカウントに登録している大人用IDiを取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdultIdi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/adult_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0177] 大人用IDi履歴取得API 対象ONEアカウントのPASMO IDi登録・解除履歴（3ヶ月分）を取得する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 現在登録しているIDiについて、IDi登録解除日時の返却値は空文字を設定する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdultIdiLinkHistories: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/adult_idi_link_histories`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0167] 小児用IDi取得API 現在ONEアカウントに登録している小児用IDiを取得する。 データ無しの場合空の配列を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIdi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/child_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0178] 小児用ポイント付与対象IDi取得API ONEアカウントに登録されていた小児用IDiのうち、過去3ヶ月間でポイント付与対象となったIDiを月毎に返却する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 対象データが無い場合もエラーとせず空のデータを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildPointIdi: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/child_point_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 機能: [P0179] 退会ユーザIDi削除API リクエストの退会処理中ONEアカウントに登録されている、大人用IDiと小児用IDiの登録情報を削除する。 また、大人用IDiは登録履歴も削除する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIdis: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/idi/remove_user_idis`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["one-user"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdiApi - functional programming interface
 * @export
 */
export const IdiApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 機能: [P0156] 大人用IDi登録API 画面から入力された大人用IDiをONEアカウントに登録する。 既に他のONEアカウントに登録されているIDiは登録する事ができない。 登録・解除記録をテーブル保持し、同日中に任意のONEアカウントから登録解除されたIDiは再度登録する事はできない。 また、同日中に同ONEアカウントにIDiを2回以上登録することはできない。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAdultIdi(body?: ReqAdultIdiPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).createAdultIdi(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0165] 小児用IDi登録API 画面から入力された小児用IDiをONEアカウントに登録する。 他ユーザによって現在登録している小児用IDiは登録する事ができない。 また、一つのONEアカウントへの小児用IDiの登録上限枚数は３枚。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createChildIdi(body?: ReqChildIdiPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).createChildIdi(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0157] 大人用IDi登録解除API 現在ONEアカウントに紐付いている大人用IDiの登録を解除する。 確認のために登録解除対象の大人用IDiもパラメータとして受け取る。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAdultIdi(body?: ReqAdultIdiPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).deleteAdultIdi(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0166] 小児用IDi登録解除API 現在ONEアカウントに登録している小児用IDiの登録を解除する。 最大3つ登録している小児用IDiから、解除対象のIDiを特定するため、解除対象の小児用IDiもパラメータとして受け取る。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteChildIdi(body?: ReqChildIdiPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).deleteChildIdi(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0158] 大人用IDi取得API 現在ONEアカウントに登録している大人用IDiを取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdultIdi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResAdultIdi>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).getAdultIdi(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0177] 大人用IDi履歴取得API 対象ONEアカウントのPASMO IDi登録・解除履歴（3ヶ月分）を取得する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 現在登録しているIDiについて、IDi登録解除日時の返却値は空文字を設定する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAdultIdiLinkHistories(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResAdultIdiLinkHistories>>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).getAdultIdiLinkHistories(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0167] 小児用IDi取得API 現在ONEアカウントに登録している小児用IDiを取得する。 データ無しの場合空の配列を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildIdi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).getChildIdi(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0178] 小児用ポイント付与対象IDi取得API ONEアカウントに登録されていた小児用IDiのうち、過去3ヶ月間でポイント付与対象となったIDiを月毎に返却する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 対象データが無い場合もエラーとせず空のデータを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChildPointIdi(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ResChildPointIdi>>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).getChildPointIdi(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 機能: [P0179] 退会ユーザIDi削除API リクエストの退会処理中ONEアカウントに登録されている、大人用IDiと小児用IDiの登録情報を削除する。 また、大人用IDiは登録履歴も削除する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeIdis(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await IdiApiAxiosParamCreator(configuration).removeIdis(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IdiApi - factory interface
 * @export
 */
export const IdiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 機能: [P0156] 大人用IDi登録API 画面から入力された大人用IDiをONEアカウントに登録する。 既に他のONEアカウントに登録されているIDiは登録する事ができない。 登録・解除記録をテーブル保持し、同日中に任意のONEアカウントから登録解除されたIDiは再度登録する事はできない。 また、同日中に同ONEアカウントにIDiを2回以上登録することはできない。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAdultIdi(body?: ReqAdultIdiPost, options?: any): AxiosPromise<void> {
            return IdiApiFp(configuration).createAdultIdi(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0165] 小児用IDi登録API 画面から入力された小児用IDiをONEアカウントに登録する。 他ユーザによって現在登録している小児用IDiは登録する事ができない。 また、一つのONEアカウントへの小児用IDiの登録上限枚数は３枚。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createChildIdi(body?: ReqChildIdiPost, options?: any): AxiosPromise<void> {
            return IdiApiFp(configuration).createChildIdi(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0157] 大人用IDi登録解除API 現在ONEアカウントに紐付いている大人用IDiの登録を解除する。 確認のために登録解除対象の大人用IDiもパラメータとして受け取る。
         * @param {ReqAdultIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdultIdi(body?: ReqAdultIdiPost, options?: any): AxiosPromise<void> {
            return IdiApiFp(configuration).deleteAdultIdi(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0166] 小児用IDi登録解除API 現在ONEアカウントに登録している小児用IDiの登録を解除する。 最大3つ登録している小児用IDiから、解除対象のIDiを特定するため、解除対象の小児用IDiもパラメータとして受け取る。
         * @param {ReqChildIdiPost} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteChildIdi(body?: ReqChildIdiPost, options?: any): AxiosPromise<void> {
            return IdiApiFp(configuration).deleteChildIdi(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0158] 大人用IDi取得API 現在ONEアカウントに登録している大人用IDiを取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdultIdi(options?: any): AxiosPromise<ResAdultIdi> {
            return IdiApiFp(configuration).getAdultIdi(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0177] 大人用IDi履歴取得API 対象ONEアカウントのPASMO IDi登録・解除履歴（3ヶ月分）を取得する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 現在登録しているIDiについて、IDi登録解除日時の返却値は空文字を設定する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdultIdiLinkHistories(options?: any): AxiosPromise<Array<ResAdultIdiLinkHistories>> {
            return IdiApiFp(configuration).getAdultIdiLinkHistories(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0167] 小児用IDi取得API 現在ONEアカウントに登録している小児用IDiを取得する。 データ無しの場合空の配列を返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildIdi(options?: any): AxiosPromise<Array<string>> {
            return IdiApiFp(configuration).getChildIdi(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0178] 小児用ポイント付与対象IDi取得API ONEアカウントに登録されていた小児用IDiのうち、過去3ヶ月間でポイント付与対象となったIDiを月毎に返却する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 対象データが無い場合もエラーとせず空のデータを返却する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChildPointIdi(options?: any): AxiosPromise<Array<ResChildPointIdi>> {
            return IdiApiFp(configuration).getChildPointIdi(options).then((request) => request(axios, basePath));
        },
        /**
         * 機能: [P0179] 退会ユーザIDi削除API リクエストの退会処理中ONEアカウントに登録されている、大人用IDiと小児用IDiの登録情報を削除する。 また、大人用IDiは登録履歴も削除する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeIdis(options?: any): AxiosPromise<void> {
            return IdiApiFp(configuration).removeIdis(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdiApi - object-oriented interface
 * @export
 * @class IdiApi
 * @extends {BaseAPI}
 */
export class IdiApi extends BaseAPI {
    /**
     * 機能: [P0156] 大人用IDi登録API 画面から入力された大人用IDiをONEアカウントに登録する。 既に他のONEアカウントに登録されているIDiは登録する事ができない。 登録・解除記録をテーブル保持し、同日中に任意のONEアカウントから登録解除されたIDiは再度登録する事はできない。 また、同日中に同ONEアカウントにIDiを2回以上登録することはできない。
     * @param {ReqAdultIdiPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public createAdultIdi(body?: ReqAdultIdiPost, options?: any) {
        return IdiApiFp(this.configuration).createAdultIdi(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0165] 小児用IDi登録API 画面から入力された小児用IDiをONEアカウントに登録する。 他ユーザによって現在登録している小児用IDiは登録する事ができない。 また、一つのONEアカウントへの小児用IDiの登録上限枚数は３枚。
     * @param {ReqChildIdiPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public createChildIdi(body?: ReqChildIdiPost, options?: any) {
        return IdiApiFp(this.configuration).createChildIdi(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0157] 大人用IDi登録解除API 現在ONEアカウントに紐付いている大人用IDiの登録を解除する。 確認のために登録解除対象の大人用IDiもパラメータとして受け取る。
     * @param {ReqAdultIdiPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public deleteAdultIdi(body?: ReqAdultIdiPost, options?: any) {
        return IdiApiFp(this.configuration).deleteAdultIdi(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0166] 小児用IDi登録解除API 現在ONEアカウントに登録している小児用IDiの登録を解除する。 最大3つ登録している小児用IDiから、解除対象のIDiを特定するため、解除対象の小児用IDiもパラメータとして受け取る。
     * @param {ReqChildIdiPost} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public deleteChildIdi(body?: ReqChildIdiPost, options?: any) {
        return IdiApiFp(this.configuration).deleteChildIdi(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0158] 大人用IDi取得API 現在ONEアカウントに登録している大人用IDiを取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public getAdultIdi(options?: any) {
        return IdiApiFp(this.configuration).getAdultIdi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0177] 大人用IDi履歴取得API 対象ONEアカウントのPASMO IDi登録・解除履歴（3ヶ月分）を取得する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 現在登録しているIDiについて、IDi登録解除日時の返却値は空文字を設定する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public getAdultIdiLinkHistories(options?: any) {
        return IdiApiFp(this.configuration).getAdultIdiLinkHistories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0167] 小児用IDi取得API 現在ONEアカウントに登録している小児用IDiを取得する。 データ無しの場合空の配列を返却する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public getChildIdi(options?: any) {
        return IdiApiFp(this.configuration).getChildIdi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0178] 小児用ポイント付与対象IDi取得API ONEアカウントに登録されていた小児用IDiのうち、過去3ヶ月間でポイント付与対象となったIDiを月毎に返却する。 3ヶ月分は今月から先々月までとし、ひと月分は1日から末日までとする。 対象データが無い場合もエラーとせず空のデータを返却する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public getChildPointIdi(options?: any) {
        return IdiApiFp(this.configuration).getChildPointIdi(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 機能: [P0179] 退会ユーザIDi削除API リクエストの退会処理中ONEアカウントに登録されている、大人用IDiと小児用IDiの登録情報を削除する。 また、大人用IDiは登録履歴も削除する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdiApi
     */
    public removeIdis(options?: any) {
        return IdiApiFp(this.configuration).removeIdis(options).then((request) => request(this.axios, this.basePath));
    }

}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 環境変数や最終コミットを見るためのエンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", ["auth0-admin"])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ヘルスチェック用エンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig: async (body: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling postTimeConfig.');
            }
            const localVarPath = `/time_config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication isAuthorized required
            // oauth required
            if (configuration && configuration.accessToken) {
                const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken("isAuthorized", [])
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 環境変数や最終コミットを見るためのエンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDebugInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResDebugInfo>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getDebugInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimeConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).getTimeConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ヘルスチェック用エンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).healthGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTimeConfig(body: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await SystemApiAxiosParamCreator(configuration).postTimeConfig(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 環境変数や最終コミットを見るためのエンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDebugInfo(options?: any): AxiosPromise<ResDebugInfo> {
            return SystemApiFp(configuration).getDebugInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimeConfig(options?: any): AxiosPromise<InlineResponse2001> {
            return SystemApiFp(configuration).getTimeConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * ヘルスチェック用エンドポイント。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: any): AxiosPromise<InlineResponse200> {
            return SystemApiFp(configuration).healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
         * @param {InlineObject} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTimeConfig(body: InlineObject, options?: any): AxiosPromise<InlineResponse2002> {
            return SystemApiFp(configuration).postTimeConfig(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 環境変数や最終コミットを見るためのエンドポイント。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getDebugInfo(options?: any) {
        return SystemApiFp(this.configuration).getDebugInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に設定された時刻を取得するAPI（環境変数でAPIの利用可否を決定する）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public getTimeConfig(options?: any) {
        return SystemApiFp(this.configuration).getTimeConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ヘルスチェック用エンドポイント。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public healthGet(options?: any) {
        return SystemApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * テスト用に時刻の設定を行うためのAPI（環境変数でAPIの利用可否を決定する）
     * @param {InlineObject} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public postTimeConfig(body: InlineObject, options?: any) {
        return SystemApiFp(this.configuration).postTimeConfig(body, options).then((request) => request(this.axios, this.basePath));
    }

}


