



















































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import OnceButton from '@/components/OnceButton.vue';
import PasmoManageDialog from '@/components/OdekakePoint/PasmoManageDialog.vue';
import { PasmoIdiRepository } from '@/repositories/PasmoIdiRepository';
import { pasmoUtils } from '@/common/pasmoUtils';
import filterUtils from '@/common/filterUtils';
import PasmoAppDownloadPanel from '@/components/PasmoAppDownloadPanel.vue';
import OpAppDownloadPanel from '@/components/OpAppDownloadPanel.vue';
import TermsPoint from '@/components/consents/TermsPoint.vue';

@Component({
  components: {
    OnceButton,
    PasmoManageDialog,
    PasmoAppDownloadPanel,
    OpAppDownloadPanel,
    TermsPoint
  }
})
export default class PasmoManage extends Vue {
  buttonLoading = false;
  adultTermOfOdekakePointUseChecked = false;
  adultTermOfPasmoUseChecked = false;
  childTermOfOdekakePointUseChecked = false;
  childTermOfPasmoUseChecked = false;
  childLegalRepresentativeChecked = false;
  isAdultPasmoIdiRegisterConfirmDialogOpening = false;
  isChildPasmoIdiRegisterConfirmDialogOpening = false;
  isRemoveAdultPasmoIdiConfirmDialogOpening = false;
  isRemoveChildPasmoIdiConfirmDialogOpening = false;
  isResultDialogOpening = false;
  readonly PASMOIDI_PLACEHOLDER = 'PB123456789012345'; //PASMOIdi入力欄のプレースホルダ
  readonly PASMO_TEXTBOX_NAME = 'PASMO ID番号';
  readonly REGISTERED_CHILD_PASMO_MAX_NUM = 3; //小児用PASMOの最大紐付け数
  readonly ADULT_PASMO_IDI_KEY = 'input_adult_pasmo_idi'; // LocalStorageのKey、odekake-point遷移時のクエリパラメータadult-pasmo-idiが格納されている
  adultPasmoIdi = '';
  childPasmoIdi = '';
  removingChildPasmoIdi = '';
  registeredAdultPasmoIdi = '';
  registeredChildPasmoIdis: string[] = [];
  resultMsg = '';
  errorMsg = '';

  created() {
    this.initialize();
  }

  async initialize() {
    await Promise.all([this.getAdultPasmoIdi(), this.getChildPasmoIdis()]);
    this.setAdultPasmoIdiFromQuery();
  }

  formatAdultPasmoIdi() {
    this.adultPasmoIdi = filterUtils.funcs.formatToHalfUpperCase(
      this.adultPasmoIdi
    );
  }

  formatChildPasmoIdi() {
    this.childPasmoIdi = filterUtils.funcs.formatToHalfUpperCase(
      this.childPasmoIdi
    );
  }

  get adultPasmoAgreed() {
    return (
      this.adultTermOfOdekakePointUseChecked && this.adultTermOfPasmoUseChecked
    );
  }

  get childPasmoAgreed() {
    return (
      this.childTermOfOdekakePointUseChecked &&
      this.childTermOfPasmoUseChecked &&
      this.childLegalRepresentativeChecked
    );
  }

  get pasmoIdiRepo() {
    return new PasmoIdiRepository();
  }

  getAdultPasmoIdi() {
    this.errorMsg = '';
    return this.pasmoIdiRepo
      .getAdultPasmoIdi()
      .then(res => {
        this.registeredAdultPasmoIdi = res.adultIdi;
      })
      .catch((errCode: number) => {
        this.handleGetPasmoIdiErr(errCode);
      });
  }

  handleGetPasmoIdiErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.errorMsg = this.$msg.get('2000011');
        break;
      default:
        this.errorMsg = this.$msg.get('2000048');
        break;
    }
  }

  getChildPasmoIdis() {
    this.errorMsg = '';
    return this.pasmoIdiRepo
      .getChildPasmoIdis()
      .then(res => {
        this.registeredChildPasmoIdis = res.childIdis;
      })
      .catch((errCode: number) => {
        this.handleGetPasmoIdiErr(errCode);
      });
  }

  openAdultPasmoIdiRegisterConfirmDialog() {
    this.$validator.validate('adultPasmoIdiForm.adultPasmoIdi').then(res => {
      if (res) {
        this.isAdultPasmoIdiRegisterConfirmDialogOpening = true;
      }
    });
  }

  openChildPasmoIdiRegisterConfirmDialog() {
    this.$validator.validate('childPasmoIdiForm.childPasmoIdi').then(res => {
      if (res) {
        this.isChildPasmoIdiRegisterConfirmDialogOpening = true;
      }
    });
  }

  registerAdultPasmoIdi() {
    this.buttonLoading = true;
    this.resultMsg = '';
    if (pasmoUtils.validatePasmoIdi(this.adultPasmoIdi)) {
      return this.pasmoIdiRepo
        .registerAdultPasmoIdi(this.adultPasmoIdi)
        .then(() => {
          this.getAdultPasmoIdi();
          this.resultMsg = this.$msg.get('2000040');
          this.adultPasmoIdi = '';
        })
        .catch((errCode: number) => {
          this.handleRegisterAdultPasmoIdiErr(errCode);
        })
        .finally(() => {
          this.isResultDialogOpening = true;
          this.isAdultPasmoIdiRegisterConfirmDialogOpening = false;
          this.buttonLoading = false;
          this.adultTermOfOdekakePointUseChecked = false;
          this.adultTermOfPasmoUseChecked = false;
        });
    } else {
      this.resultMsg = this.$msg.get('2000042');
      this.isResultDialogOpening = true;
      this.isAdultPasmoIdiRegisterConfirmDialogOpening = false;
      this.buttonLoading = false;
      this.adultTermOfOdekakePointUseChecked = false;
      this.adultTermOfPasmoUseChecked = false;
    }
  }

  handleRegisterAdultPasmoIdiErr(errCode: number) {
    switch (errCode) {
      case 40001:
        this.resultMsg = this.$msg.get('2000042');
        break;
      case 40900:
        this.resultMsg = this.$msg.get('2000043');
        break;
      case 40901:
        this.resultMsg = this.$msg.get('2000044');
        break;
      case 40902:
        this.resultMsg = this.$msg.get('2000045');
        break;
      case 40903:
        this.resultMsg = this.$msg.get('2000046');
        break;
      case 40905:
        this.resultMsg = this.$msg.get('2000053');
        break;
      case 40910:
        this.resultMsg = this.$msg.get('2000044');
        break;
      case 40911:
        this.resultMsg = this.$msg.get('2000043');
        break;
      case 50000:
        this.resultMsg = this.$msg.get('2000011');
        break;
      default:
        this.resultMsg = this.$msg.get('2000047');
        break;
    }
  }

  registerChildPasmoIdi() {
    this.buttonLoading = true;
    this.resultMsg = '';
    if (pasmoUtils.validatePasmoIdi(this.childPasmoIdi)) {
      return this.pasmoIdiRepo
        .registerChildPasmoIdi(this.childPasmoIdi)
        .then(() => {
          this.getChildPasmoIdis();
          this.resultMsg = this.$msg.get('2000040');
          this.childPasmoIdi = '';
        })
        .catch((errCode: number) => {
          this.handleRegisterChildPasmoIdiErr(errCode);
        })
        .finally(() => {
          this.isResultDialogOpening = true;
          this.isChildPasmoIdiRegisterConfirmDialogOpening = false;
          this.buttonLoading = false;
          this.childTermOfOdekakePointUseChecked = false;
          this.childTermOfPasmoUseChecked = false;
          this.childLegalRepresentativeChecked = false;
        });
    } else {
      this.resultMsg = this.$msg.get('2000042');
      this.isResultDialogOpening = true;
      this.isChildPasmoIdiRegisterConfirmDialogOpening = false;
      this.buttonLoading = false;
      this.childTermOfOdekakePointUseChecked = false;
      this.childTermOfPasmoUseChecked = false;
      this.childLegalRepresentativeChecked = false;
    }
  }

  handleRegisterChildPasmoIdiErr(errCode: number) {
    switch (errCode) {
      case 40001:
        this.resultMsg = this.$msg.get('2000042');
        break;
      case 40904:
        this.resultMsg = this.$msg.get('2000049');
        break;
      case 40906:
        this.resultMsg = this.$msg.get('2000050');
        break;
      case 40908:
        this.resultMsg = this.$msg.get('2000043');
        break;
      case 40909:
        this.resultMsg = this.$msg.get('2000051');
        break;
      case 40912:
        this.resultMsg = this.$msg.get('2000043');
        break;
      case 50000:
        this.resultMsg = this.$msg.get('2000011');
        break;
      default:
        this.resultMsg = this.$msg.get('2000047');
        break;
    }
  }

  openRemoveAdultPasmoIdiConfirmDialog() {
    this.isRemoveAdultPasmoIdiConfirmDialogOpening = true;
  }

  openRemoveChildPasmoIdiConfirmDialog(childPasmoIdi: string) {
    this.removingChildPasmoIdi = childPasmoIdi;
    this.isRemoveChildPasmoIdiConfirmDialogOpening = true;
  }

  removeAdultPasmoIdi() {
    this.buttonLoading = true;
    return this.pasmoIdiRepo
      .removeAdultPasmoIdi(this.registeredAdultPasmoIdi)
      .then(() => {
        this.getAdultPasmoIdi();
        this.resultMsg = this.$msg.get('2000041');
      })
      .catch((errCode: number) => {
        this.handleRemovePasmoIdiErr(errCode);
      })
      .finally(() => {
        this.isRemoveAdultPasmoIdiConfirmDialogOpening = false;
        this.isResultDialogOpening = true;
        this.buttonLoading = false;
      });
  }

  handleRemovePasmoIdiErr(errCode: number) {
    switch (errCode) {
      case 50000:
        this.resultMsg = this.$msg.get('2000011');
        break;
      default:
        this.resultMsg = this.$msg.get('2000052');
        break;
    }
  }

  removeChildPasmoIdi() {
    this.buttonLoading = true;
    return this.pasmoIdiRepo
      .removeChildPasmoIdi(this.removingChildPasmoIdi)
      .then(() => {
        this.getChildPasmoIdis();
        this.resultMsg = this.$msg.get('2000041');
      })
      .catch((errCode: number) => {
        this.handleRemovePasmoIdiErr(errCode);
      })
      .finally(() => {
        this.isRemoveChildPasmoIdiConfirmDialogOpening = false;
        this.isResultDialogOpening = true;
        this.buttonLoading = false;
      });
  }

  // クエリパラメータに `adult-pasmo-idi` が含まれ、かつ `this.adultPasmoIdi`, `this.registeredAdultPasmoIdi` が空であるとき、
  // クエリパラメータの値を大人用 IDi のフォームに自動的に挿入する。
  setAdultPasmoIdiFromQuery() {
    // 他への影響を避けるため読込時点でlocalStorage内から必ず削除する。
    let localStorageIDi = localStorage.getItem(this.ADULT_PASMO_IDI_KEY) || '';
    localStorage.removeItem(this.ADULT_PASMO_IDI_KEY);

    if (this.adultPasmoIdi || this.registeredAdultPasmoIdi) {
      return;
    }

    // undefinedの可能性がある。
    let idi = this.$route.query['adult-pasmo-idi'] || '';
    if (Array.isArray(idi)) {
      // 最初に登場するクエリパラメータを採用する。
      // クエリが "?adult-pasmo-idi=foo&adult-pasmo-idi=bar" であったとき、"foo" が採用される。
      idi = idi.shift() || '';
    }

    this.adultPasmoIdi = idi || localStorageIDi;
    this.formatAdultPasmoIdi();
  }
}
