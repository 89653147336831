



























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import UsageHistoryAndPointDetail from '@/components/OdekakePoint/tabs/UsageHistoryAndPointDetail.vue';
import { Location } from 'vue-router';
import PasmoManage from '@/components/OdekakePoint/tabs/PasmoManage.vue';
import About from '@/components/OdekakePoint/tabs/About.vue';

@Component({
  components: {
    UsageHistoryAndPointDetail,
    PasmoManage,
    About
  }
})
export default class P0175 extends Vue {
  readonly TAB_USAGE_HISTORY_AND_POINT_DETAIL = 'info';
  readonly TAB_PASMO_MANAGE = 'manage';
  readonly TAB_ABOUT = 'about';
  readonly ADULT_PASMO_IDI_KEY = 'input_adult_pasmo_idi'; // LocalStorageのKey、odekake-point遷移時のクエリパラメータadult-pasmo-idiが格納されている

  /**
   * タブの左からの並び順を定義する。並び順を変更する場合はここを変更すること。
   */
  readonly LIST_TAB_ORDER = [
    this.TAB_USAGE_HISTORY_AND_POINT_DETAIL,
    this.TAB_PASMO_MANAGE,
    this.TAB_ABOUT
  ];

  /**
   * このページの表示タブをコントロールするための値。
   */
  active_tab = this.LIST_TAB_ORDER.indexOf(
    this.TAB_USAGE_HISTORY_AND_POINT_DETAIL
  );

  created() {
    this.doOnCreated();
  }

  doOnCreated() {
    this.handleQueryTab();
  }

  // ページ内でクエリが書き換えられた際に表示タブの出し分けを行う
  @Watch('$route.query.tab')
  onQueryErrorChanged() {
    this.handleQueryTab();
  }

  // ログイン判定
  get isAuthenticatedOk() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }

  /**
   * 画面ロード時のクエリパラメータによって表示するタブを制御する
   * string型ではなかった、LIST_TAB_ORDERで定義されていなかった場合は初期化時に定義されている通り「小田急ポイントとは」を表示する
   */
  handleQueryTab() {
    const query = { ...this.$route.query };
    // 未ログイン時、クエリに'tab'もしくは'adult-pasmo-idi'を含む場合、対象のクエリを消去する（コンテンツはクエリに依存せず未ログイン用の値が表示されているため変動しない）
    // 未ログイン状態でも任意のクエリを設定し、GA計測できるようにするため一律削除はしない
    if (!this.isAuthenticatedOk) {
      // クエリパラメータ'tab'削除
      delete query.tab;
      // クエリパラメータ'adult-pasmo-idi'削除
      delete query['adult-pasmo-idi'];
      this.updateLocation({
        query: query
      });
      return;
    }

    // 未ログイン状態から遷移した際にクエリパラメータが消失するため、localStorage内に値が存在していれば、タブをmanageに変更
    if (localStorage.getItem(this.ADULT_PASMO_IDI_KEY)) {
      this.active_tab = this.LIST_TAB_ORDER.indexOf(this.TAB_PASMO_MANAGE);
      return;
    }

    // LIST_TAB_ORDERで定義された文字列であれば、初期表示タブを更新する
    if (
      typeof query.tab === 'string' &&
      this.LIST_TAB_ORDER.includes(query.tab)
    ) {
      this.active_tab = this.LIST_TAB_ORDER.indexOf(query.tab);
      return;
    }
  }

  /**
   * v-tabs-itemsをクリックしたときに、クエリパラメータの値を変更する
   * ハッシュパラメータはそのまま保持する
   */
  updateQueryTabValue() {
    const hash =
      this.LIST_TAB_ORDER[this.active_tab] === this.TAB_ABOUT
        ? this.$route.hash || ''
        : '';
    this.updateLocation({
      query: { tab: this.LIST_TAB_ORDER[this.active_tab] },
      hash: hash
    });
  }

  updateLocation(location: Location) {
    this.$router.push(location).catch(() => {
      // 現在と同じページにvue-routerで移動しようとしたときにNavigationDuplicatedエラーが発生するが、クエリパラメータを変更するためには仕方ないのでエラーを許容する
    });
  }

  clickLogin() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: '/odekake-point' } });
  }

  clickSignup() {
    this.$auth.loginWithRedirect({ initialDisplay: 'signup' });
  }
}
